<template>
  <div class="player">
    <img
      v-if="!showBackgroundImage"
      :src="`/assets/images/${image}.webp`"
      :alt="title"
      class="player__image"
      loading="lazy"
    />
    <!-- Background overlay -->
    <div v-if="!showBackgroundImage" class="player__image-overlay"></div>

    <!-- Left section with image and title -->
    <section class="player__left">
      <img
        :src="`/assets/images/${image}.webp`"
        :alt="title"
        class="player__info-image"
      />
      <div class="player__info-text">
        <Text is="p3"> {{ title }}.</Text>
      </div>
    </section>

    <!-- Center section with controls -->
    <section class="player__center">
      <div v-if="loadingPercent !== 100" class="player__loader">
        <Spinner />
        <Text is="p3">{{ t('player.loading') }} {{ loadingPercent }} %</Text>
      </div>

      <div v-else class="player__controls">
        <button
          :disabled="!playing || !hasPreviousTrack"
          @click="playPreviousTrack"
        >
          <Icon icon="player-previous" :size="40" />
        </button>
        <button :disabled="!playing" @click="backward">
          <Icon icon="player-backward" :size="40" />
        </button>
        <button @click="togglePlayPause">
          <Icon :icon="playing ? 'player-pause' : 'player-play'" :size="70" />
        </button>
        <button :disabled="!playing" @click="forward">
          <Icon icon="player-forward" :size="40" />
        </button>
        <button :disabled="!playing || !hasNextTrack" @click="playNextTrack">
          <Icon icon="player-next" :size="40" />
        </button>
        <button :disabled="!playing" @click="stop">
          <Icon icon="player-stop" :size="50" />
        </button>
        <button
          :disabled="!playing"
          @click="toggleLoop"
          :class="{ active: loopEnabled }"
        >
          <Icon icon="player-loop" :size="40" />
        </button>
      </div>

      <!-- Hidden wave element -->
      <div
        ref="player"
        :class="[
          'player__wave',
          { 'player__wave--loading': loadingPercent !== 100 }
        ]"
      ></div>
    </section>

    <!-- Right section with icons -->
    <section class="player__right">
      <span @click.stop="toggleFavorite">
        <Text v-if="!isFavorite" is="p3">
          {{ t('player.favorites.add') }}
        </Text>
        <Text v-else is="p3">{{ t('player.favorites.remove') }}</Text>
      </span>
      <button @click.stop="toggleFavorite">
        <Icon
          v-if="isFavorite"
          icon="favorite"
          :size="40"
          :style="{ color: 'var(--color-accent-50)' }"
        />
        <Icon v-else icon="favorite-outlined" :size="40" />
      </button>
    </section>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, computed, ref, watch, nextTick } from 'vue'
import { useRouter } from 'vue-router'
import WaveSurfer from 'wavesurfer.js'
import { Spaces } from '@/data/spaces'
import { useMainStore } from '@/state/index'
import { lang } from '@/services/i18n'
import { useI18n } from 'vue-i18n'
import Prices from '@/components/Prices.vue'

defineOptions({
  name: 'PlayerComponent'
})

// Initialize states and constants
const { t } = useI18n()
const store = useMainStore()
const router = useRouter()

const routesWithoutBackgroundImage = [
  'do-nothing',
  'just-breathe',
  'wise-words'
]

const showBackgroundImage = computed(() => {
  const routeName = router.currentRoute.value.name

  return (
    typeof routeName === 'string' &&
    routesWithoutBackgroundImage.includes(routeName)
  )
})

const defaultImage = 'rain-undercover'
const defaultTitle = 'Rain undercover'

const image = ref<string>(defaultImage)
const title = ref<string>(defaultTitle)
const wavesurferRef = ref<WaveSurfer | null>(null)
const player = ref<HTMLElement | null>(null)
const playing = computed(() => store.isPlaying) // Get playing state from store
const loadingPercent = ref(0)
const loopEnabled = ref<boolean>(
  localStorage.getItem('loop-enabled') === 'true'
) // Load from localStorage

// Compute whether the user has a subscription
const hasPlan = computed(() => store.userHasSubscription)

const isFavorite = computed(() => {
  const soundId = trimLanguageSuffix(store.playingSound?.[lang]?.soundId ?? '')
  return store.userFavorites.includes(soundId)
})

// Setting loop to default true
// If there's no value in localStorage, set loopEnabled to true and save it
if (localStorage.getItem('loop-enabled') === null) {
  loopEnabled.value = true
  localStorage.setItem('loop-enabled', 'true')
}

// Helper function to remove -en or -es suffix from soundId for favorites
function trimLanguageSuffix(soundId: string) {
  return soundId.replace(/-(en|es)$/, '')
}

// Use the store's toggleFavorite function
const toggleFavorite = async () => {
  if (!store.playingSound) return

  const soundId = trimLanguageSuffix(store.playingSound?.[lang]?.soundId || '')

  // Determine if the sound is currently a favorite
  const isCurrentlyFavorite = store.userFavorites.includes(soundId)

  // Toggle the favorite status in the store
  await store.toggleFavorite(soundId)

  // Show success toast depending on the action
  store.setGlobalToast({
    visible: true,
    title: isCurrentlyFavorite
      ? t('toast.favorites.removedFromFavorites')
      : t('toast.favorites.addedToFavorites'),
    message: `${store.playingSound?.[lang]?.title} ${
      isCurrentlyFavorite
        ? t('toast.favorites.removedFromFavoritesMessage')
        : t('toast.favorites.addedToFavoritesMessage')
    }`,
    type: isCurrentlyFavorite ? 'warning' : 'success'
  })
}

// Watch for sound changes in the router query params
watch(
  () => router.currentRoute.value.query.sound,
  (newSoundId) => {
    if (typeof newSoundId === 'string') {
      handleSoundChange(newSoundId)
    } else {
      // If no sound is provided in the query param, default to "rain-undercover"
      handleSoundChange(store.defaultSoundId)
    }
  },
  { immediate: true }
)

// Handle sound change and update the playingSound in the store
function handleSoundChange(newSoundId: string) {
  const sound = findSpaceBySoundId(newSoundId)
  if (sound) {
    store.setPlayingSound(sound) // Set the full sound object in the store
  } else {
    store.setPlayingSound(findSpaceBySoundId(store.defaultSoundId)) // Default sound
  }
}

// Find space by soundId and return the full sound object
function findSpaceBySoundId(soundId: string) {
  for (const key in Spaces) {
    if (
      Spaces[key].en.soundId === soundId ||
      Spaces[key].es.soundId === soundId
    ) {
      return Spaces[key]
    }
  }
  return null
}

// Watch for changes in sound or language to update the UI
watch(
  [() => store.playingSound, () => lang],
  ([newSound, currentLang]) => {
    if (newSound) {
      image.value = newSound.imageId || defaultImage
      title.value = newSound[currentLang]?.title || defaultTitle
    } else {
      image.value = defaultImage
      title.value = defaultTitle
    }
  },
  { immediate: true }
)

// Initialize WaveSurfer instance
function initWaveSurfer() {
  nextTick().then(() => {
    if (wavesurferRef.value) {
      fadeOutAndStop().then(() => {
        wavesurferRef?.value?.destroy()
        wavesurferRef.value = null
        initPlayer()
        autoplay()
      })
    } else {
      initPlayer()
    }
  })
}

// Watch for changes and reinitialize WaveSurfer
watch([() => store.playingSound], initWaveSurfer, { immediate: true })

onMounted(() => {
  nextTick(() => {
    player.value = document.querySelector('.player__wave') as HTMLElement
    loopEnabled.value = localStorage.getItem('loop-enabled') === 'true'
    initWaveSurfer()
  })
})

// Initialize the player
function initPlayer() {
  setTimeout(() => {
    const waveColor = getComputedStyle(
      document.documentElement
    ).getPropertyValue('--color-player-wave')
    const progressColor = getComputedStyle(
      document.documentElement
    ).getPropertyValue('--color-player-wave-progress')
    const cursorColor = getComputedStyle(
      document.documentElement
    ).getPropertyValue('--color-player-wave-cursor')

    if (!player.value) return

    // Create WaveSurfer instance
    wavesurferRef.value = WaveSurfer.create({
      backend: 'MediaElement', // Use MediaElement for better mobile support
      container: player.value,
      waveColor,
      progressColor,
      cursorColor,
      height: 40,
      barHeight: 1,
      normalize: false,
      responsive: true
    })

    wavesurferRef.value.setVolume(0.5)
    loadingPercent.value = 0

    const soundPath = `/assets/sounds/${store.playingSound?.[lang].soundId}.mp3`
    const soundNotFoundPath = `/assets/sounds/sound-not-found-${lang}.mp3`

    // Conditionally load the sound or soundNotFound based on subscription
    if (!hasPlan.value && store.playingSound?.requiresPlan) {
      wavesurferRef.value.load(soundNotFoundPath)
    } else {
      wavesurferRef.value.load(soundPath)
    }

    // Error Handling
    wavesurferRef.value.on('error', () => {
      wavesurferRef.value?.load(soundNotFoundPath)
      loadingPercent.value = 100

      store.setGlobalToast({
        visible: true,
        title: t('toast.player.soundNotFound.title'),
        message: t('toast.player.soundNotFound.message'),
        type: 'warning'
      })
    })

    // Update loading percent
    wavesurferRef.value.on('loading', (percent: number) => {
      loadingPercent.value = Math.min(percent, 100)
    })

    wavesurferRef.value.on('ready', async () => {
      loadingPercent.value = 100 // Ensure loading shows 100% when ready
      const canAutoplay = await checkAutoplay() // Check if autoplay is allowed
      if (canAutoplay) {
        wavesurferRef.value?.play() // Start playback if autoplay is permitted
      } else {
        store.togglePlaying(false) // Set playing state to false if autoplay isn't allowed
      }

      window.addEventListener('keypress', (event) => {
        if (wavesurferRef.value) {
          if (event.code === 'Space' || event.key === ' ') {
            event.preventDefault()
            wavesurferRef.value.playPause()
          }
          if (event.code === 'ArrowRight') {
            wavesurferRef.value.skip(10)
          }
          if (event.code === 'ArrowLeft') {
            wavesurferRef.value.skip(-10)
          }
        }
      })

      wavesurferRef?.value?.on('audioprocess', () => {
        store.togglePlaying(wavesurferRef.value?.isPlaying() ?? false)
      })

      // Update the onFinish handler in `initPlayer` to respect loop state
      wavesurferRef.value?.on('finish', () => {
        if (loopEnabled.value) {
          play() // Restart the current track
        } else {
          playNextTrack() // Play the next track or restart playlist if at the end
        }
      })
    })
  }, 100) // Small delay to avoid loading issues
}

// Fade out and stop function
function fadeOutAndStop(duration = 500): Promise<void> {
  return new Promise<void>((resolve) => {
    if (!wavesurferRef.value) {
      resolve()
      return
    }

    loadingPercent.value = 0

    const originalVolume = wavesurferRef?.value.getVolume()
    const step = originalVolume / (duration / 100)

    const fadeOutInterval = setInterval(() => {
      const currentVolume = wavesurferRef.value?.getVolume() ?? 0
      const nextVolume = Math.max(0, currentVolume - step)

      wavesurferRef.value?.setVolume(nextVolume)

      if (nextVolume <= 0) {
        clearInterval(fadeOutInterval)
        wavesurferRef.value?.stop()
        wavesurferRef.value?.setVolume(originalVolume)
        resolve()
      }
    }, 100)
  })
}

// Play function
function play() {
  if (!hasPlan.value && store.playingSound?.requiresPlan) {
    return
  }

  return wavesurferRef?.value?.play()
}

// Autoplay function
async function autoplay() {
  if (await checkAutoplay()) play()
}

// Check autoplay capability
async function checkAutoplay() {
  const audio = new Audio('./assets/sounds/silence.mp3')
  audio.volume = 0
  try {
    if (showSubscriptionModal()) return // Show modal if no subscription
    await audio.play()
    return true
  } catch {
    return false
  }
}

// Toggle play/pause state
const togglePlayPause = () => {
  if (playing.value) {
    pause()
    store.togglePlaying(false)
  } else {
    if (showSubscriptionModal()) return // Show modal if no subscription
    play()
    store.togglePlaying(true)
  }
}

// Pause function
function pause() {
  return wavesurferRef?.value?.pause()
}

// Stop function
function stop() {
  return wavesurferRef?.value?.stop()
}

// Backward function
function backward() {
  return wavesurferRef.value?.skip(-10)
}

// Forward function
function forward() {
  return wavesurferRef.value?.skip(10)
}

const hasPreviousTrack = computed(() => store.currentTrackIndex > 0)
const hasNextTrack = computed(
  () => store.currentTrackIndex < store.filteredOrder.length - 1
)

// Play the previous track
const playPreviousTrack = () => {
  if (hasPreviousTrack.value) {
    store.decrementTrackIndex()
    updateTrack()
  }
}

// Play the next track
const playNextTrack = () => {
  if (hasNextTrack.value) {
    store.incrementTrackIndex()
    updateTrack()
  }
}

// Update the current track based on the index
const updateTrack = () => {
  const nextSoundId = store.filteredOrder[store.currentTrackIndex]
  router.push({
    query: { ...router.currentRoute.value.query, sound: nextSoundId }
  })
}

// Toggle loop state
const toggleLoop = () => {
  loopEnabled.value = !loopEnabled.value
  localStorage.setItem('loop-enabled', loopEnabled.value.toString()) // Save to localStorage

  store.setGlobalToast({
    visible: true,
    title: loopEnabled.value
      ? t('toast.player.loopEnabled.title')
      : t('toast.player.loopDisabled.title'),
    message: loopEnabled.value
      ? t('toast.player.loopEnabled.message')
      : t('toast.player.loopDisabled.message'),
    type: 'success'
  })
}

function showSubscriptionModal() {
  if (!hasPlan.value && store.playingSound?.requiresPlan) {
    store.setGlobalModal({
      visible: true,
      title: `${title.value}.`,
      subtitle: t('modal.premium.subtitle'),
      body: t('modal.premium.body'),
      component: Prices
    })
    return true
  }
  return false
}
</script>

<style scoped lang="scss">
.player {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background-color: var(--color-player-bg);
  border-top: var(--size-1) solid var(--color-neutral-70);
  // border-left: var(--size-1) solid var(--color-neutral-70);
  width: 100%;
  height: var(--size-player-height);
}

.player__left,
.player__right {
  display: none;
  align-items: center;
  column-gap: var(--size-40);
  margin: 0 var(--size-40);

  @media (min-width: $device-lg) {
    display: flex;
  }
}

.player__center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  margin: 0;
  flex: 1;
}

.player__left {
  height: 100%;
  width: 20%;
  flex: 1;
}

.player__right {
  justify-content: flex-end;
  height: 100%;
  width: 20%;
  text-align: right;
  flex: 1;

  &:hover {
    color: var(--color-accent-50);
  }

  button,
  span {
    cursor: pointer;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-neutral-70);
    border: none;
    border-radius: var(--size-radius-round);
    color: inherit;
    width: var(--size-100);
    height: var(--size-100);
    padding: 0;

    &:hover {
      transform: scale(1.1);
      transform-origin: center;
      transition: 0.3s ease-in-out;

      .icon {
        transform: scale(1.1);
        transform-origin: center;
        transition: 0.3s ease-in-out;
      }
    }
  }
}

.player__info-image {
  width: var(--size-100);
  height: var(--size-100);
  object-fit: cover;
  border-radius: var(--size-10);
}

.player__info-text {
  flex: 1;
  justify-content: space-between;
}

.player__controls {
  display: flex;
  gap: var(--size-20);

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    cursor: pointer;
    color: inherit;
    width: var(--size-120);
    height: var(--size-player-height);
    padding: 0;
    outline: 0;
    border: 0;

    &:active,
    &.active {
      outline: 0;
      background-color: var(--color-accent-50-32);
    }

    &:disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    &:hover {
      background-color: var(--color-accent-50-32);

      .icon {
        transform-origin: center;
        transition: 0.3s ease-in-out;
        transform: scale(1.2);
      }
    }
  }
}

.player__loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: var(--size-player-loader-max-width);
  gap: var(--size-30);
}

.player__wave {
  display: none; /* Hide wave shape */
  pointer-events: none;
}

.player__image {
  position: absolute;
  bottom: var(--size-player-height);
  left: 0;
  right: 0;
  width: 100%;
  height: calc(100vh - var(--size-player-height));
  filter: blur(var(--size-200));
  object-fit: cover;
  z-index: var(--z-index-behind);
}

.player__image-overlay {
  position: absolute;
  bottom: var(--size-player-height);
  left: 0;
  right: 0;
  width: 100%;
  height: calc(100svh - var(--size-player-height));
  background-color: var(--color-tools-overlay);
  z-index: var(--z-index-behind);
}
</style>
